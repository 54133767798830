import 'date-fns';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import GroupIcon from '@material-ui/icons/Group';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import UserDialog from '../../components/UserDialog';

import axios from 'axios';
import { URL_API, API_KEY } from '../../constants';

class Calendarios extends Component {

  constructor() {
    super();
    this.state = {
      currentMonth:new Date().getMonth(),
      currentYear:new Date().getFullYear(),
      currentType:0,
      calendarItems: [],
      openDialog:false,
      openModal:false,
      currentGroup: [],
      updatePlazas:null,
      done: false,
      updateUserName:'',
      userOption:''
    }
  }

  componentDidMount() {
    var user = localStorage.getItem('user');
     //const token = localStorage.getItem('token');

     // si hay token, redirecciona
    if(!user){
      this.props.history.push('/');
    }

    const userjs = JSON.parse(user);

    if (userjs && userjs.level !== '10') 
      this.props.history.push('/');

   
    this.apiFectchCalendar();
  }

  async apiFectchCalendar(month = '', year = '') {
       this.setState({ done: false});
      
        var cMonth ='';
        var cYear ='';
        if(month === ''){
          cMonth = this.state.currentMonth + 1;
        }else{
          cMonth = month + 1;
        }
        if(year === ''){
          cYear = this.state.currentYear;
        }else{
          cYear = year;
        }        
           

        axios.get( URL_API + '/admin/calmonth/user',
          {
              params: {
                 secure_key:API_KEY,
                 month: cMonth,
                 year: cYear,
                 fecha:'01-01-2021', 
                 userid: 2, 
              }
          })
        .then(response => { 
          console.log(response.data);
          this.setState({ done: true});
          this.setState({ calendarItems: response.data});
        })
        .catch(error => {
            console.log(error.response);
        });       

  }

  render() {

	const handleChangeMonth = (event) => {
	  
	  this.setState({ currentMonth: event.target.value });
	  this.setState({ done: false});
	 
	};

	const handleChangeYear = (event) => {
	  
	  this.setState({ currentYear: event.target.value });
	  this.setState({ done: false});
	  
	};

	const handleChangeType= (event) => {
	  
	  this.setState({ currentType: event.target.value });
	  this.setState({ done: false});
	

	};

  const users = this.state.users;
  return (
  <div>
    <Grid container justify="center">
      <Grid item xs={12}>
      <Paper component="form" className="topinformer">
        <Select
          labelId="select-month"
          id="month-select"
          value={this.state.currentMonth}
          onChange={handleChangeMonth}
        >
          <MenuItem value={0}>Enero</MenuItem>
          <MenuItem value={1}>Febrero</MenuItem>
          <MenuItem value={2}>Marzo</MenuItem>
          <MenuItem value={3}>Abril</MenuItem>
          <MenuItem value={4}>Mayo</MenuItem>
          <MenuItem value={5}>Junio</MenuItem>
          <MenuItem value={6}>Julio</MenuItem>
          <MenuItem value={7}>Agosto</MenuItem>
          <MenuItem value={8}>Septiembre</MenuItem>
          <MenuItem value={9}>Octubre</MenuItem>
          <MenuItem value={10}>Noviembre</MenuItem>
          <MenuItem value={11}>Diciembre</MenuItem>
        </Select>
        <Divider className="divider" orientation="vertical" />
        <Select
          labelId="select-month"
          id="month-select"
          value={this.state.currentYear}
          onChange={handleChangeYear}
        >
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2024}>2024</MenuItem>
                        <MenuItem value={2025}>2025</MenuItem>
                        <MenuItem value={2026}>2026</MenuItem>
        </Select>
        <Divider className="divider" orientation="vertical" />
            <Select
              labelId="select-type"
              id="type-select"
              value={this.state.currentType}
              onChange={handleChangeType}
            >
              <MenuItem value={0}>Todos</MenuItem>
              <MenuItem value='M'>Mixtos</MenuItem>
              <MenuItem value='P'>Pilates</MenuItem>
              <MenuItem value='F'>Funcional</MenuItem>
              <MenuItem value='Y'>Yoga</MenuItem>
            </Select>
        </Paper> 
      </Grid>        

    </Grid>
    <Grid id="adminPilates" container justify="center">
    {!this.state.done ? (
          <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>

        ) : (
        <Grid item xs={12} sm={12} md={12} direction="row"
                  className="carditem">
        
                {this.state.calendarItems.map((item, indx) => {

                  return( 
                    <Card  variant="outlined" className={`group_card group_${item.assigned}`} key={indx}>
                      <CardContent>
                        <Typography variant="caption" display="block" align="center" id={`day_${item.label}_${item.day}`} >
                          {item.week_day}
                        </Typography>
                        <Typography variant="caption" display="block" align="center" id={`day_${item.label}_${item.day}`} >
                          CUERPOPLENO
                        </Typography>
						  <Button color="primary" className="buttonCard" >
						    Desactivar el día
						  </Button>
						  <Button color="primary" className="buttonCard">
						    Desactivar horas
						  </Button>                          
                      </CardContent>
                    </Card>
                  );
                
                })}
          
        </Grid>
        )}


     </Grid>
    </div>
      ); //return



  } //render

}

Calendarios.propTypes = {

}

export default Calendarios;  