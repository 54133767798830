import 'date-fns';
import React, { Component, useState } from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import UserDialog from '../../components/UserDialog';

import axios from 'axios';
import { URL_API, API_KEY } from '../../constants';

class Contabilidad extends Component {

  constructor() {
    super();
    this.state = {
      currentMonth:new Date().getMonth(),
      currentYear:new Date().getFullYear(),
      currentType:0,
      useradmin:'',
      done: false,
      items: [],
      itemFuncional: [],
      itemYoga: [],
      itemMixto: [],
      itemVip: [],
      itemSo: [],
      itemSc: [],
      itemAtrasados:[],
      itemNutricion: [],
      itemBaile: [],
      itemReadapta: [],
      itemPersonal: [],
      itemMatricula: [],
      itemSumTransferecia:0,
      itemSumEfectivo:0,
      itemSumMes:0,
      userOption:'',
      openOption:'',
      sessionOption:'',
      usersMatricula:[],
      usersNutricion:[],
      usersReadaptacion:[],
      usersEpersonal:[],
      usersBaile:[],
      isOpen:[],
      valuePayment:[],
      isPay:[],
      totalPos :0,
      totalTransfer:0,
    }

  }

  

  componentDidMount() {
    var user = localStorage.getItem('user');
     //const token = localStorage.getItem('token');

     // si hay token, redirecciona
    if(!user){
      this.props.history.push('/');
    }

    const userjs = JSON.parse(user);

   this.setState({ useradmin: userjs.user_login}); 
   this.apiContabilidadUsers(this.state.currentMonth,this.state.currentYear,userjs.user_login);
   
  }

  async apiContabilidadUsers(month = '', year= '', useradmin='') {
         
        var cMonth ='';
        var cYear ='';
        if(month === ''){
          cMonth = this.state.currentMonth + 1;
        }else{
          cMonth = month + 1;
        }
        if(year === ''){
          cYear = this.state.currentYear;
        }else{
          cYear = year;
        }        
                

        axios.get( URL_API + '/admin/contabilidad',
          {
              params: {
                 secure_key:API_KEY,
                 month: cMonth,
                 year: cYear,
                 admin: useradmin 
              }
          })
        .then(response => { 
          console.log(response.data);
          this.setState({ items: response.data.contabilidad});
          this.setState({ totalPos: response.data.totalPos});
          this.setState({ totalTransfer: response.data.totalTransfer});
          this.setState({ done: true});
        })
        .catch(error => {
            console.log(error.response);
        });
        
  }

  async apiAssignPay(year = '', month = '', user= '', pay= '', useradmin='') {
         
    var cMonth ='';
    var cYear ='';
    if(month === ''){
      cMonth = this.state.currentMonth + 1;
    }else{
      cMonth = month + 1;
    }
    if(year === ''){
      cYear = this.state.currentYear;
    }else{
      cYear = year;
    }        
            

    axios.get( URL_API + '/admin/assignpay',
      {
          params: {
             secure_key:API_KEY,
             month: cMonth,
             year: cYear,
             admin: useradmin, 
             user: user, 
             pay: pay, 
          }
      })
    .then(response => { 
      console.log(response.data);

    })
    .catch(error => {
        console.log(error.response);
    });
    
}

  render() {

const handleChangeMonth = (event) => {
  
  this.setState({ currentMonth: event.target.value });
  this.setState({ done: false});
  this.apiContabilidadUsers(event.target.value,this.state.currentYear,this.state.useradmin);

};

const handleChangeYear = (event) => {
  
  this.setState({ currentYear: event.target.value });
  this.setState({ done: false});
  this.apiContabilidadUsers(this.state.currentMonth,event.target.value,this.state.useradmin);
 
};

const handleChangePayment = (event, idx) => {
  
  console.log('handleChangePayment',event.target.value, idx)

  let valuePaymentCopy = this.state.valuePayment;
  valuePaymentCopy[idx] = event.target.value;
  this.setState({ valuePayment: valuePaymentCopy });
};

const handleAssignPayment = (idx, uId) => {
  
  console.log('handleAssignPayment', idx, this.state.valuePayment[idx], uId)

  this.apiAssignPay(this.state.currentYear,this.state.currentMonth, uId, this.state.valuePayment[idx],this.state.useradmin);
 
};

const toggleOpen = (idx) => {
  console.log(idx)
  let isOpenCopy = this.state.isOpen;
  isOpenCopy[idx] = true;
  this.setState({ isOpen: isOpenCopy });
};

  return (

    <Grid id="adminformes" container justify="center">

              <Grid item xs={12}>
              <Paper component="form" className="topinformer">
                <Select
                  labelId="select-month"
                  id="month-select"
                  value={this.state.currentMonth}
                  onChange={handleChangeMonth}
                >
                  <MenuItem value={0}>Enero</MenuItem>
                  <MenuItem value={1}>Febrero</MenuItem>
                  <MenuItem value={2}>Marzo</MenuItem>
                  <MenuItem value={3}>Abril</MenuItem>
                  <MenuItem value={4}>Mayo</MenuItem>
                  <MenuItem value={5}>Junio</MenuItem>
                  <MenuItem value={6}>Julio</MenuItem>
                  <MenuItem value={7}>Agosto</MenuItem>
                  <MenuItem value={8}>Septiembre</MenuItem>
                  <MenuItem value={9}>Octubre</MenuItem>
                  <MenuItem value={10}>Noviembre</MenuItem>
                  <MenuItem value={11}>Diciembre</MenuItem>
                </Select>
                <Divider className="divider" orientation="vertical" />
                <Select
                  labelId="select-month"
                  id="month-select"
                  value={this.state.currentYear}
                  onChange={handleChangeYear}
                >
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2024}>2024</MenuItem>
                        <MenuItem value={2025}>2025</MenuItem>
                        <MenuItem value={2026}>2026</MenuItem>
                </Select>
                </Paper> 
              </Grid>            
    {!this.state.done ? (
          <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>

        ) : (
          <Grid item xs={12} sm={12} md={6}>
          <div className="matriculas">
            <div><Chip label={`${this.state.items.length}`} /> \ <Chip label={`${this.state.totalPos}`} color="primary"/> \ <Chip label={`${this.state.totalTransfer}`} color="secondary"/></div>
            <List dense={false}>
                {this.state.items.map((user, indx) => {
                  
                  var colorChip = user.pay_method == 'pos_cash' ? "primary" : 'secondary';
                  return( 
                    <ListItem key={indx} dense>

                    <ListItemText  primary={`${indx+1} ${user.display_name}`}

                     />
                     &nbsp;                  

                      <ListItemSecondaryAction >
                        <Chip label={`${user.total}` } variant="outlined" 
                        color={colorChip}
                        onClick={() => {toggleOpen(indx);}} 
                        />
                    {this.state.isPay[indx] && (
                        <Chip label="pay" variant="outlined" color="primary"/>
                    )}  
                    {this.state.isOpen[indx] && (
                      <FormControl className="textPayment">
                      <Input
                        className="standard-adornment-payment"
                        type='text'
                        size="small"
                        onChange={(e) => handleChangePayment(e, indx)}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle search visibility"
                              onClick={() => {handleAssignPayment(indx, user.id_user);}}
                            
                            id={`pay-${user.id_user}`} 
                            >
                              <AttachMoneyIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    )}                        
                      </ListItemSecondaryAction>                    
                    </ListItem>

                  );
  
                })}
            </List>
           </div>
        </Grid>
    )}

     </Grid>

      );//return



  } //render

}

Contabilidad.propTypes = {

}

export default Contabilidad;  