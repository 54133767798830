import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
//import { inicializarFirebase, preguntarPermisos } from "../../firebaseConfig";
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import axios from 'axios';

import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';


class Notifications extends Component {

  constructor(props) {
    super(props);
    this.state = {     
            currentOption: 0,
            openSnackBar:false,
            sessions : [],
            message:'',
            done: true,
            }
  };

  componentDidMount(){
    var user = localStorage.getItem('user');
     // si hay token, redirecciona
    if(!user)
      this.props.history.push('/');

    var token = localStorage.getItem('token');
    if(token){
      this.setState({ currentOption: 1 });
    }else {
      this.setState({ currentOption: 0 });
    }

  }



  render() {

  const handleChangeOption = (event) => {
    
    this.setState({ currentOption: event.target.value });
        
    // if(preguntarPermisos()){
    //   this.setState({ currentOption: 1 });
    // }else{
    //   this.setState({ currentOption: 0 });
    // }
  };

    return (
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={12}>
              <div className="header-section header-section__funcional">NOTIFICACIONES</div>
            </Grid>
            <Grid   container
                  direction="row"
                  justify="center"
                  alignItems="center">
              <Grid item xs={12}>
                <p>
                <Select
                  labelId="select-month"
                  id="month-select"
                  value={this.state.currentOption}
                  defaultValue={this.state.currentOption}
                  onChange={handleChangeOption}

                >
                  <MenuItem value={0}>Desactivado</MenuItem>
                  <MenuItem value={1}>Activado</MenuItem>
                </Select>
                </p>
              </Grid>
            </Grid>
          </Grid>
        )

  }
}

export default Notifications;