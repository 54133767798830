import 'date-fns';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Visibility from '@material-ui/icons/Visibility';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import UserDialog from '../../components/UserDialog';

import axios from 'axios';
import { URL_API, API_KEY } from '../../constants';

class Informes extends Component {

  constructor() {
    super();

    this.state = {
      currentMonth:new Date().getMonth(),
      currentYear:new Date().getFullYear(),
      currentDay:new Date().getDate(),
      currentType:0,
      order:0,
      done: false,
      items: [],
      originalItems:[],
      userOption:'',
      openOption:'',
      sessionOption:'',
      routeParam:'',
    }

   // Bind the this context to the handler function
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    var user = localStorage.getItem('user');
     //const token = localStorage.getItem('token');

     // si hay token, redirecciona
    if(!user){
      this.props.history.push('/');
    }

    const userjs = JSON.parse(user);

    if (userjs && userjs.level !== '10') 
      this.props.history.push('/');

   
    this.apiInformeUsers();
  }

  handleUpdate = (e) => {
    
   this.apiInformeUsers(this.state.currentMonth, this.state.currentType);
    this.setState({ openOption: false});
  }; 

  async apiInformeUsers(month = '', type= 0, year= '', day ='' ) {
         
        var cMonth ='';
        var cYear ='';
        var cDay = '';
        if(month === ''){
          cMonth = this.state.currentMonth + 1;
        }else{
          cMonth = month + 1;
        }
        if(year === ''){
          cYear = this.state.currentYear;
        }else{
          cYear = year;
        } 
        if(day === ''){
          cDay = this.state.currentDay;
        }else{
          cDay = day;
        }   

        console.log('mes ' +cMonth);
         console.log('year ' +cYear); 
         console.log('cDay ' +cDay);   
        axios.get( URL_API + '/users/informes',
          {
              params: {
                 secure_key:API_KEY,
                 filter:type,
                 month: cMonth,
                 year: cYear,
                 day: cDay,
              }
          })
        .then(response => { 
          console.log(response.data);
          this.setState({ done: true});
          this.setState({ items: response.data});
          this.setState({ originalItems: response.data});
        })
        .catch(error => {
            console.log(error.response);
        });
        
  }

  render() {

const handleChangeMonth = (event) => {
  
  this.setState({ currentMonth: event.target.value });
  this.setState({ done: false});
  this.apiInformeUsers(event.target.value, this.state.currentType, this.state.currentYear);
};

const handleChangeYear = (event) => {
  
  this.setState({ currentYear: event.target.value });
  this.setState({ done: false});
  this.apiInformeUsers(this.state.currentMonth, this.state.currentType, event.target.value);
};

const handleChangeDay = (event) => {
  
  this.setState({ currentDay: event.target.value });
  this.setState({ done: false});
  this.apiInformeUsers(this.state.currentMonth, this.state.currentType, this.state.currentYear, event.target.value);
};

const handleChangeType= (event) => {
  
  this.setState({ currentType: event.target.value });
  this.setState({ done: false});
  this.apiInformeUsers(this.state.currentMonth, event.target.value, this.state.currentYear);

};

  const handleChangeOrder = (event) => {
      
      this.setState({ order: event.target.value});
      console.log(event.target.value);
      if(event.target.value === 'libres'){
        console.log(this.state.items.sort((a,b) =>  b.libres-a.libres ))
      }
      if(event.target.value === 'faltas'){
        console.log(this.state.items.sort((a,b) =>  b.faltas-a.faltas ))
      }  
      if(event.target.value === 'apuntadas'){
        console.log(this.state.items.sort((a,b) =>  b.asiste-a.asiste ))
      }  
      if(event.target.value === 'cobrados'){
        console.log(this.state.items.sort((a,b) =>  b.pay-a.pay ))
      }   
      if(event.target.value === 'pendientes'){
        console.log(this.state.items.sort((a,b) =>  a.pay-b.pay ))
      }     
  };

  const handleOpenOption = (e) => {
      var user_split = e.target.id.split("-");
      if(user_split && user_split[1]){
        this.setState({ userOption: user_split[1]});
        this.setState({ sessionOption: user_split[2]});
        this.setState({ openOption: true});       
      }

  };  

  const handleCloseOption = (e) => {
    
   this.setState({ openOption: false});
  }; 

  const handleChangeSearch = (e) => {
    
    console.log(e.target.value.length);
    if(e.target.value.length > 0){
      this.setState({ routeParam: e.target.value});
      const regex = new RegExp("^" + e.target.value, "i");
      const search_items = this.state.items.filter(user => user.firstname.match(regex));
      this.setState({items:search_items});
    }else{
      this.setState({items:this.state.originalItems});
    }

           
  } 

  const handleListSearch = (e) => {
    var query = document.getElementById("query").value;

 
  }

  return (

    <Grid id="adminformes" container justify="center">

              <Grid item xs={12} className="topdashboard" container justify="center">
                 <Grid item xs={2}>
                
                    <Select
                      labelId="select-day"
                      id="day-select"
                    value={this.state.currentDay}
                    onChange={handleChangeDay}
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={11}>11</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                      <MenuItem value={13}>13</MenuItem>
                      <MenuItem value={14}>14</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                      <MenuItem value={16}>16</MenuItem>
                      <MenuItem value={17}>17</MenuItem>
                      <MenuItem value={18}>18</MenuItem>
                      <MenuItem value={19}>19</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={21}>21</MenuItem>
                      <MenuItem value={22}>22</MenuItem>
                      <MenuItem value={23}>23</MenuItem>
                      <MenuItem value={24}>24</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={26}>26</MenuItem>
                      <MenuItem value={27}>27</MenuItem>
                      <MenuItem value={28}>28</MenuItem>
                      <MenuItem value={29}>29</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={31}>31</MenuItem>
                    </Select>
                  </Grid>            
              <Grid item xs={4}>
                  <Select
                    labelId="select-month"
                    id="month-select"
                    value={this.state.currentMonth}
                    onChange={handleChangeMonth}
                  >
                    <MenuItem value={0}>Enero</MenuItem>
                    <MenuItem value={1}>Febrero</MenuItem>
                    <MenuItem value={2}>Marzo</MenuItem>
                    <MenuItem value={3}>Abril</MenuItem>
                    <MenuItem value={4}>Mayo</MenuItem>
                    <MenuItem value={5}>Junio</MenuItem>
                    <MenuItem value={6}>Julio</MenuItem>
                    <MenuItem value={7}>Agosto</MenuItem>
                    <MenuItem value={8}>Septiembre</MenuItem>
                    <MenuItem value={9}>Octubre</MenuItem>
                    <MenuItem value={10}>Noviembre</MenuItem>
                    <MenuItem value={11}>Diciembre</MenuItem>
                  </Select>
               </Grid>
                 <Grid item xs={3}>
                
                    <Select
                      labelId="select-year"
                      id="year-select"
                    value={this.state.currentYear}
                    onChange={handleChangeYear}
                    >
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2024}>2024</MenuItem>
                        <MenuItem value={2025}>2025</MenuItem>
                        <MenuItem value={2026}>2026</MenuItem>
                    </Select>
                  </Grid>
                 <Grid item xs={2}>
                
                    <Select
                      labelId="select-type"
                      id="type-select"
                      value={this.state.currentType}
                      onChange={handleChangeType}
                    >
                      <MenuItem value={0}>Todos</MenuItem>
                      <MenuItem value='M'>Mixtos</MenuItem>
                      <MenuItem value='P'>Pilates</MenuItem>
                      <MenuItem value='F'>Funcional</MenuItem>
                      <MenuItem value='Y'>Yoga</MenuItem>
                      <MenuItem value='E'>Especial</MenuItem>
                      <MenuItem value='V'>Vip</MenuItem>
                      <MenuItem value='S'>S.4 / S.8</MenuItem>
                      <MenuItem value='BT'>Baja Tem</MenuItem>
                    </Select>
                 </Grid>
              </Grid>
              <Grid item xs={12} container justify="center">
                 <Grid item xs={3}>
                    <Typography variant="subtitle2">{`Reg ${this.state.items.length}`}</Typography>
                  </Grid>
                 <Grid item xs={5}>
                    <FormControl className="textSearch">
                      <Input
                        id="standard-adornment-search"
                        type='text'
                        size="small"
                        onChange={handleChangeSearch}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle search visibility"
                             onClick={handleListSearch}
                              
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>              
                  </Grid>
                 <Grid item xs={4}>
                
                    <Select
                      labelId="select-order"
                      id="order-select"
                    value={this.state.order}
                    onChange={handleChangeOrder}
                    >
                      <MenuItem value={0}>Ordenar</MenuItem>
                      <MenuItem value='libres'>libres</MenuItem>
                      <MenuItem value='faltas'>faltas</MenuItem>
                      <MenuItem value='apuntadas'>apuntadas</MenuItem>
                      <MenuItem value='cobrados'>cobrados</MenuItem>
                      <MenuItem value='pendientes'>sin cobrar</MenuItem>
                    </Select>
                  </Grid>
              </Grid>            
    {!this.state.done ? (
          <div style={{ width: '100%' }}>
            <CircularProgress />
          </div>

        ) : (
        <Grid item xs={12} sm={12} md={6}>
          <div className="matriculas">
            <List dense={false}>
                {this.state.items.map((user, indx) => {
                var groups = user.groups
                var str_groups = groups.map(function(group) {
                  var array_day =['D','L','M','X','J','V','S'];
                  var day_label = array_day[group.day_group] + group.hour_group;
                    return (<Chip size="small" className={`grouptype_${group.type}`} label={day_label} />);
                  
                });
                  return( 
                    <ListItem key={indx} dense className={`userpay_${user.pay} usermetodo_${user.metodo} userstate_${user.state} atrasado_${user.atrasado}`}>

                    <ListItemText  primary={`${user.firstname} ${user.lastname}`}
                        secondary={
                          <React.Fragment>
                            <div>
                              {str_groups}
                            </div>
                          </React.Fragment>
                        }

                     />
                     &nbsp;
                    <Avatar className="infavatar infasiste">{user.asiste}</Avatar>
                    <Avatar className="infavatar inflibres">{user.libres}</Avatar>
                    <Avatar className="infavatar inffaltas">{user.faltas}</Avatar>
                      <ListItemSecondaryAction >
                        <Avatar className="infavatar inftotal" id={`id-${user.user_id}`}  onClick={handleOpenOption}>{user.pago}</Avatar>
                      </ListItemSecondaryAction>                    
                    </ListItem>

                  );
  
                })}
            </List>
           </div>
        </Grid>
        )}
      <Dialog id="opyiondialog" fullScreen
          open={this.state.openOption} onClose={handleCloseOption} >
        <DialogContent>
          <UserDialog idUser={this.state.userOption} idSession={-10} action={this.handleUpdate} currentmonth={this.state.currentMonth} currentyear={this.state.currentYear}/>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOption} color="primary" >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
     </Grid>

      ); //return



  } //render

}

Informes.propTypes = {

}

export default Informes;  