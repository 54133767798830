import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import {AccountCircle, Visibility, VisibilityOff } from '@material-ui/icons';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import logohome from '../../assets/img/logo-mun42-login.png';
import Typography from '@material-ui/core/Typography';

import { URL_API, API_KEY } from '../../constants';

import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openMessage:false,
      openMessageFisioterapia:false,
      openMessageCuentame:false,
      notification:[],
      userLogin:false,
      user_name:'',
      password :'',
      showPassword : false,
      textLogin:'',
      pageNutricion: '',
      pageFisioterapia: ''
    }
  };

  componentDidMount() {
    var user = localStorage.getItem('user');
     //const token = localStorage.getItem('token');
     //const tokenParse = JSON.parse(token);
    console.log('objetoObtenido: ', JSON.parse(user));
     // si hay token, redirecciona

    if(user){
      this.setState({ userLogin: user});
    }


    this.apiFetchMessage();

  }

  async apiFetchMessage(date = '') {
    var user = localStorage.getItem('user');
    if(user){
        let currentUser = JSON.parse(user);
        let id = currentUser.ID;


        if(localStorage.getItem('user_edit')){
          id = localStorage.getItem('user_edit');
        }

        axios.get('https://mun42.com/wp-json/app/v1/user/messages/',
          {
              params: {
                 secure_key:'1ec16e4666b5b2a9e2705ba7c739cf47',
                 user_id:id,
              }
          })
        .then(response => { 
          console.log(response.data);
            if(response.data.message){
              this.setState({ notification: response.data});
              this.setState({ openMessage: true});
            }

        })
        .catch(error => {
            console.log(error.response);
        });
    }
  }

  //Soltamos Plaza
  async apiDeleteMessage(message) {
    var user = localStorage.getItem('user');
    
    if(user){
        let currentUser = JSON.parse(user);
        let id = currentUser.ID;
        
        axios.post('https://mun42.com/wp-json/app/v1/user/messages/', {
          secure_key: "1ec16e4666b5b2a9e2705ba7c739cf47",
          user_id: id,
          message_id:message
        })
        .then(response => { 

        })
        .catch(error => {
            console.log(error.response);

        });
    }
  }

  handleListPilates = () => {
        this.props.history.push('/home/sessions/pilates');
    }

  handleListYoga = () => {
        this.props.history.push('/home/sessions/yoga');
    
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleEmailChange = (event) => { 
         
    this.setState({user_name: event.target.value});  

  };

  handlePasswordChange = (event) => {        
    this.setState({password: event.target.value});  

  };

  handleSubmit = () => {

    const { user_name, password } = this.state;    

    this.login(user_name, password);
  };

  async login(username, password) {
      this.setState({ textLogin: ''});
      var token = localStorage.getItem('token');
      axios.post(URL_API + '/user/login', {
        secure_key: "1ec16e4666b5b2a9e2705ba7c739cf47",
        user_name: username,
        passwd:password,
        token:token
      })
      .then(response => {
          console.log(response); 
        if(response.data.ID > 0){
          localStorage.setItem('user', JSON.stringify(response.data) );
          this.props.history.push('/home');
        }else{
          this.setState({ textLogin: 'usuario o contraseña incorrecto'});
        }
        
      })
      .catch(error => {
          console.log(error.response);
          this.props.history.push('/');
          this.setState({ textLogin: 'error'});
      });
  }

  async getWpPage(page){
      axios.get('https://mun42.com/wp-json/wp/v2/pages/' + page,
      {
          params: {
            secure_key:'1ec16e4666b5b2a9e2705ba7c739cf47',
          }
      })
    .then(response => { 
      console.log(response.data);
      if(page == 708){
        this.setState({ pageCuentame: response.data.content.rendered});
      }else{
        this.setState({ pageFisioterapia: response.data.content.rendered});
      }
      
    })
    .catch(error => {
        console.log(error.response);
    });
  }

  render(){

  const handleOpenCuentame = () => {
      this.getWpPage(708);
      this.setState({ openMessageCuentame: true});
   }

  const createMarkupCuentame = () => {
    return {__html: this.state.pageCuentame};
  }


const handleCloseCuentame = () => {
  
  this.setState({ openMessageCuentame: false});
};


  console.log(this.state.userLogin);
  if(this.state.userLogin){

    return (
        <Grid container justify="center">
          <Grid item xs={12} sm={4} md={4}>
            <Button id="btn-pilates" onClick={this.handleListPilates}>
              <img src="../../assets/img/menu-pilates-shadows.png"  className="responsive" alt=""  />
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button id="btn-funcional" onClick={this.handleListYoga}>
              <img src="../../assets/img/menu-yoga-shadows.png" className="responsive" alt="" />
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Button id="btn-funcional" onClick={handleOpenCuentame}>
              <img src="../../assets/img/menu-cuentame-shadows.png" className="responsive" alt="" />
            </Button>
          </Grid>
          <Dialog
            open={this.state.openMessageCuentame}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseCuentame}
            id="opyiondialog" fullScreen
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
              <div dangerouslySetInnerHTML={createMarkupCuentame()} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCuentame} color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </Dialog> 
        </Grid> 
    ); //return
  }else{
      return (
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={4}>
              <Card>
              
                <form id="Login">
                    <img src={logohome} alt="cuerpo pleno" />
                    <Typography variant="overline" display="block" gutterBottom>
                      LOGIN
                    </Typography>
                    <FormControl>
                      <InputLabel htmlFor="filled-adornment-password">username</InputLabel>
                      <FilledInput
                        id="filled-adornment-password"
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        type='text'
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle"
                              edge="end"
                            >
                              <AccountCircle />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <div></div>
                    <FormControl>
                      <InputLabel htmlFor="filled-adornment-password">fecha</InputLabel>
                      <FilledInput
                        id="filled-adornment-password"
                        type='text'
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                             
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end"
                            >
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <div>
                      <span className="span-error">{this.state.textLogin}</span>
                        <Button id="btn-login" variant="contained" color="secondary" onClick={this.handleSubmit}>
                          comenzar
                        </Button>
                     
                    </div>
                </form>
              </Card>
            </Grid>           
          </Grid>
      );//return      
  }

  }; //render

}

Home.propTypes = {

}

export default withRouter(Home);