import React from 'react';
import { withRouter } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import {Lock, Edit } from '@material-ui/icons';
import logo from '../assets/img/logo-mun42-only.png';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MoodIcon from '@material-ui/icons/Mood';
import HomeIcon from '@material-ui/icons/Home';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  topBar:{
    background: '#fff',   
  },  
  responsive:{
    'max-width': '100%',
    height: 'auto'   
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  menuButton2: {
    marginLeft: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
}));



const AppHeader = props => {
  
  const classes = useStyles();

  const handleClickHome = () => {
    props.history.push('/home');
  };

  const handleClickDashboard = () => {
    props.history.push('/dashboard');
  };

  const [state, setState] = React.useState({
    left: false,
    openModal:false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    setState({ ...state, left: false });
    props.history.push('/');
  };

  const handlePerfil = () => {
    setState({ ...state, left: false });
    props.history.push('/home/perfil');
  };

  const handleNotification = () => {
    setState({ ...state, left: false });
    props.history.push('/home/perfil/notifications');
  };

  const handleDeleteEdit = () => {
    localStorage.removeItem("user_edit");
    props.history.push('/dashboard');
  };

  const handleUserNew = () => {
      props.history.push('/dashboard/user/0');
  };

  const user = JSON.parse(localStorage.getItem('user'));

  var button =  '';


  if (user && user.level === '10') {
      button =  <Fab size="small" color="secondary" aria-label="home" className="fab fab--right" onClick={handleClickDashboard}>
                  <Lock />
              </Fab>;
  }

  var button2 =  '';


  if (localStorage.getItem('user_edit')) {
      button2 =  <Fab fontSize="large" size="small" color="secondary" aria-label="home" className="fab fab--left fab--edit" onClick={handleDeleteEdit}>
                  <Edit />
              </Fab>;
  }

  var button3 =  '';

  if (user && user.level === '10') {
  
      button3 =  <Fab size="small" color="primary" aria-label="new" className="fab fab--left fab--add" onClick={handleUserNew}>
                   <Icon fontSize="large" color="primary">add_circle</Icon>
              </Fab>;
  }

  if(user){
  return (
    <div>
        <AppBar position="fixed" className={classes.topBar}>
          <Toolbar>

            <IconButton edge="start" className={classes.menuButton} aria-label="menu" onClick={toggleDrawer('left', true)}>
              <MenuIcon  />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <img src={logo} className={classes.responsive} alt="mun42" />
            </Typography>
            
            <IconButton edge="end" className={classes.menuButton2} aria-label="lock" onClick={handleClickHome}>
                <HomeIcon />
              </IconButton>

          </Toolbar>
          
        </AppBar>

          <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)}>

            <Divider />
            <List>
                <Avatar>
                  <AssignmentIndIcon />
                </Avatar>
                <ListItem button key="passw" onClick={handlePerfil}>
                  <ListItemIcon><MoodIcon /></ListItemIcon>
                  <ListItemText primary="Perfil" />
                </ListItem>
                 <ListItem button key="permission" onClick={handleNotification}>
                  <ListItemIcon><NotificationsNoneIcon /></ListItemIcon>
                  <ListItemText primary="Activar Notificaciones" />
                </ListItem> 
                 <ListItem button key="logout" onClick={handleLogout}>
                  <ListItemIcon><PowerSettingsNewIcon /></ListItemIcon>
                  <ListItemText primary="Cerrar sesión" />
                </ListItem>      
            </List>
          </Drawer>
          {button2}
          {button3}
          {button}
    </div>
    
  ); //return

  }else{
    return (<div></div>);
  }

}


AppHeader.propTypes = {

}


export default withRouter(AppHeader);